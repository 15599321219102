/* Nav */

.menu-footer .active {
	border-bottom: 1px solid inherit;
}

.menu-footer li {
	margin-bottom: 1rem;
}


/* Off-canvas */

.offcanvas-header {
	display: block;
	padding: 0;
}


.offcanvas-start {
	border-right: 0;
}

.offcanvas a {
	color: #000;
	text-decoration: none;
}

.offcanvas a:hover {
	color: #df4766;
	text-decoration: none;
}


/* Toast */

.toast {
	background-color: rgba(255,255,255, 1);
	box-shadow: 0 0.1rem 0.1rem rgb(0 0 0 / 10%);
}

.toast:not(.showing):not(.show) {
	display: none;
}

.liveToast {
	display: none;
}


/* forms */

.form-control {
	font-size: inherit;
}

.form-control:focus {
	border: 2px solid #000;
	box-shadow: none;
} 


.nav-link {
	color: rgba(251, 201, 31, 1);
}

.nav-tabs .nav-link {
	border: none;
	border-radius: 0;
}

.nav-tabs .nav-link.active {
	border: none;
	background: rgba(251, 201, 31, 1);	
	color: #000

}

.nav-tabs .nav-link.active:hover {
	background: rgba(251, 201, 31, 1);
	color: #000

}


.nav-tabs .nav-link:hover {
	background: rgba(251, 201, 31, .5);
	color: #000;
	border: none;
}



/* Custom Toggle Button */
/* By Dom (dcode) : https://dev.to/dcodeyt/creating-a-css-only-toggle-switch-5cg3 */
.language-toggler {
	align-self: center; 
	padding-right: 4rem;
	display: none; // TODO remove
}

.toggle {
	--width: 50px;
	--height: calc(var(--width) / 2);
	--border-radius: calc(var(--height) / 2);
	display: inline-block;
	cursor: pointer;
}

.toggle__input {
	display: none;
}	

.toggle__fill {
	position: relative;
	width: var(--width);
	height: var(--height);
	border-radius: 20px;
	background: #fcd023 ;
	transition: background 0.2s;
	font-size: .75rem;
	color: #000;
	font-weight: 500;
}

.toggle__fill::after {
	content: "\f12b";
	fill: #fcd023;
	font-family: bootstrap-icons !important;
	display: inline-block;
	position: absolute;
	top: 0;
	left: 0;
	height: var(--height);
	width: var(--height);
	background: #ffffff;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
	border-radius: 20px;
	transition: transform 0.2s;
	margin: 0px;
	padding: 4px 6px;
	color: #16433e;
}	

.toggle__text {
	margin: 3px 0;
}			

.toggle__input:checked ~ .toggle__fill {
	background: #df4766;
	color: #fff;
}

.toggle__input:checked ~ .toggle__fill::after {
	transform: translateX(var(--height)) rotate(180deg);;
}	


/** Back to top **/
.backtotop--action {
    text-align: right;
}

.backtotop--action i {
    background: #fc0;
    color: #fff;
    padding: 3px;
}

.backtotop--action a {
    text-decoration: none;
    color: #000;
}

.back-to-top::after {
	content: "";
	display: inline-block;
    font-family: bootstrap-icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    vertical-align: -.275em;
	-webkit-font-smoothing: antialiased;
	font-size: 2rem;
	padding: 0.25rem 0 0 1rem;
}



:root {
    --swiper-pagination-color: #df4766;
}


/* Device styles */
.device {
	position: relative;
	z-index: 1050;
	overflow: hidden;
	margin: 0 auto;
	background-size: 100%;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-touch-callout: none;
	-khtml-user-select: none;
}

.device__screen {
	position: relative;
	overflow: hidden;
	height: 100%;
	text-align: left;
	border-radius: 4px 4px 0 0;
}

.device--alt .device__screen {
	background: #4ECE77;
}


/*SWIPER NEW */


.swiper-button-next, .swiper-button-prev {
	cursor: pointer;
	position: initial;
	width: auto;
	height: auto;
	margin-top: auto;
	color: inherit;
}


.swiper-button-next::after, .swiper-button-prev::after {
	display: none;
}

/* Swiper */
.swiper {
	width: 100%;
	height: 100%;
	max-height: auto !important;
}

.swiper p {
	margin-bottom: 2rem;
}

.swiper-slide {
	font-size: 1rem;
	/* background: #fff; */
	padding: .2rem;	  
	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
	height: auto !important;
}

.homeTeamSwiper .swiper-slide {
	align-items: start;
}

.swiper-slide img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: contain;
	max-height: 600px;
}

.swiper-pagination, .swiper-pagination-1  {
	position: relative !important;
	padding: 1rem;
	text-align: center;
}

.swiper-button-disabled {
	opacity: .5 !important;
}

.swiper li {
	margin-bottom: .5rem;
}

.home-swiper--head {
    height: 100px;
    width: 180px;
    padding: 1rem;
}

.home-swiper--img {
	width: 100%;
	height: 100%;
}

.services-swiper--img {
	width: clamp(80px, 180px, 190px) !important;
	height: clamp(100px, 120px, 150px) !important;
	padding: 1rem;
	align-self: center;
}


/* 
.mySwiper2 .swiper-slide, .cardSwiper .swiper-slide{
	text-align: center;
	border: 1px solid #ddd;
	border-radius: 4px;
	box-shadow: var(--shadow-elevation-medium);
}

.mySwiper .swiper-content {
	padding: 1rem 2rem;
}

.mySwiper2 .number, .cardSwiper .number {
	position: absolute;
	top: 0;
	right: 10px;
	font-size: 2rem;
	font-weight: 600;
	color: #b5ca17;
}

.mySwiper2 img {
object-fit: cover;	  
}

.mySwiper .swiper-slide img {
	margin: auto;
	padding: 1.2rem .6rem;
}

.mySwiper h3 {
	font-size: 1.4rem;
	margin-bottom: 2rem;
}

.cardSwiper h3{
	font-size: 1.4rem;
	margin-bottom: 2rem;
}

.cardSwiper p {
	margin-bottom: 2rem;
}

.cardSwiper img {
	object-fit: contain;
}

.cardSwiper h3 {
	padding-top: 1rem;
} */




.loading-container {
	width: 60px;
	height: 60px;
	position: fixed;
	z-index: 9999;
	border-radius: 50%;
}

.loading-animation {
	position: absolute;
	overflow: hidden;
	margin:auto;
	top:0;
	left:0;
	right: 0;
	bottom:0;
	height:30px;
	width:30px;
	border-radius: 50%;
	animation-name: loading-mys;
	animation-duration: 1.2s;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
	border: 8px solid;
}


@keyframes loading-mys {
	0% {
		transform:rotate(0deg)
				  translate(-30px)
				  rotate(0deg);
		border-color: green;
	}


	20% {
		border-color: red;
	}

	40% {
		border-color: grey;
	}

	60% {
		border-color: blue;
	}

	80% {
		border-color: yellow;
	}

	100%{
		border-color: lightsalmon;
		transform:rotate(360deg)
				translate(-30px)
				rotate(-360deg);
	}

}





/* Page Loader */
.js .loading::before,
.js .loading::after {
	content: '';
	position: fixed;
	z-index: 1000;
}

.js .loading::before {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: var(--color-bg);
}

.js .loading::after {
	top: 50%;
	left: 50%;
	width: 60px;
	height: 60px;
	margin: -30px 0 0 -30px;
	border-radius: 50%;
	opacity: 0.4;
	background: var(--color-link);
	animation: loaderAnim 0.7s linear infinite alternate forwards;

}

@keyframes loaderAnim {
	to {
		opacity: 1;
		transform: scale3d(0.5,0.5,1);
	}
}


/* Accordion */


.accordion {
	--bs-accordion-border-color: 0;
	--bs-accordion-border-width: 0;
	--bs-accordion-bg: transparent !important;
}

.accordion-item:first-of-type, .accordion-item:last-of-type, .accordion-item:first-of-type .accordion-button, .accordion-item:last-of-type .accordion-button.collapsed {
	border-radius: 0;
}

.accordion-header {
	padding: 1rem 0;
	border-bottom: 1px solid black;
}

.accordion-header strong {
	max-width: 780px;
}

.accordion-button, .accordion-button:not(.collapsed), .accordion-button:focus {
	padding: 2rem 0;
	border-radius: 0;
}

.accordion-body {
	padding: 1rem 0;
	max-width: 780px;
}

.full-width {
	max-width: 100% !important;
}

.accordion-button {
	font-size: 1.5rem;
}

.accordion-button:not(.collapsed) {
	background-color: transparent;
	color: inherit;
}

.accordion-button:focus {
	box-shadow: none;
}


/* Observer */
.cta-section {
	opacity: 0
}



input.form-control:focus {
	border: 0;
}


