/* home */ 
.philosophy .card-title {
	margin: 0 0 1rem 0;
}

.philosophy .card-body {
	padding: 1rem 1.8rem;
}



/* TEAM & WORK */

.view a::after {
	content: none !important;
	display: none;
}



.tabscontent {
	pointer-events: none;
}

.tabscontent__item {
	padding-top: 100px;
	font-size: 1.2rem;
	line-height: 1.65;
}


.tabscontent__item--current,
.tabscontent__item--current ~ .btn--back {
	pointer-events: auto;
}



.post-thumbnail {
	border-radius: 2px;
}


/* TEAM */

.team .poster__img {
	max-height: 100%;
	min-width: 300px;
	width: clamp(100px, 100%, 300px);
}

.team .poster__box {
	width: 250px;
	height: 250px;
	left: -20px;
	position: absolute;
}


/* News */

.news article {
	background: #fff;
	/* padding: 2rem; */
}

.news a {
	/* color: inherit; */
	text-decoration: none;
	background:
	  linear-gradient(
		to right,
		transparent,
		transparent
	  ),
	  linear-gradient(
		to right,
		$deep-pink,
		$orange,
		$yellow
	);
	background-size: 100% 3px, 0 3px;
	background-position: 100% 100%, 0 100%;
	background-repeat: no-repeat;
	transition: background-size 400ms;
}

.news a:hover {
	background-size: 0 3px, 100% 3px;
}

.news h1 {
	text-transform: uppercase;
	text-decoration: none;
}

@include media-breakpoint-down(lg) {
	.news h1 {
		font-size: 2rem;
	}
}



.news h2 {
	padding: .25rem 0 1.5rem 0;
	margin:0;
	padding-top: 1.5rem;
	text-transform: uppercase;
	line-height: .5;
	font-weight: bold;
	font-size: 1.45rem !important;
    
}

.news .article-illustration {
	height: 350px;
	border-radius: 5px;
}

@include media-breakpoint-up(xl) {
	.news .article-illustration {
		height: 300px;
		border-radius: 5px;
	}
}
@include media-breakpoint-up(xxl) {
	.news .article-illustration {
		height: 260px;
		border-radius: 5px;
	}
}


.news .article-illustration:hover {
	cursor: pointer;
}

article h2 {
	line-height: 2.5rem !important;
	font-size: 1.75rem !important;
}

article p a {
	color: #da3e5a;
}

@media(max-width:960px) {
	.news .article-illustration {
		height: 300px;
	}
}

.news .badge {
	margin: 0.75rem 0;
	background-color: #da3e5a;
	font-weight: 400;
}

.news span {
/* 	padding: .35rem .5rem; 
	border-radius: 4px; */
	font-size: .85rem;
	font-weight: 400;
/* 	margin: 0 0 .75rem 0;
	background: #fafafe; */
}

.news .author-thumbnail {
	width: 32px;
	border-radius: 50%;
}

.news .news-content {
	padding: 1rem 0 0 0;
}

.news .navigation {
	display: flex;
}

.news .pagination {
    margin: 5rem auto 0 auto;
    justify-content: center;
    align-items: center;
    align-self: center;
}

.news ul.pagination {
	width: max-content;
	border-radius: 5px;
	background: #414a52;
	color: #da3e5a;
}

.news ul.pagination a {
	color: #fff;
}

.news ul.pagination a:hover {
	color: #da3e5a;
}

.news span.current {
	font-weight: 600;
}

.news a.page-numbers {
	font-weight: 400;
}

.news .pagination span, .news .pagination .page-numbers {
	padding: 1rem;
	border-radius: 4px;
}



.news .article-inlist {
	padding: 2px; 
	margin-top: -6px;
	background: transparent linear-gradient(137deg, #AEC316 0%, #003831 100%) 0% 0% no-repeat padding-box;
	border-radius: 10px;
}

.news .preview-content {
	padding: 1rem;
}


.news .next-previous-post {
	padding: 0.5rem;
}

.news .image-caption, .news figcaption {
	font-size: .85rem;
	line-height: 1.4;
	text-align: center;
}


/* WORK */
.work {
	overflow-x: visible !important;
}

.work .poster__img {
	max-width: calc(100% - 6rem);
	max-height: 800px;
	min-width: 300px;
	/* margin-left: -20px; */
}

/* .work .swiper-slide img {
	border: 1px solid #ddd;
} */

.work .poster__box, .team .poster__box {
    position: absolute;
    top: -50px;
    left: 0px;
    width: 300px;
    height: 300px;
    border: 10px solid;
}

.work .poster__deco, .team .poster__deco {
    font-size: 2em;
    line-height: 1;
    margin: 0em;
    color: #da3e5a;
    writing-mode: vertical-rl;
    position: absolute;
    top: 0;
    right: 1%;
}

@media(min-width:1200px) {
	.work .poster__deco, .team .poster__deco {
		font-size: 2em;
		line-height: 1;
		margin: 0em;
		color: #da3e5a;
		writing-mode: vertical-rl;
		position: absolute;
		top: 0;
		right: 0;
	}
} 


.work .project-pagination a.swiper-button-disabled {
	visibility: hidden;
}




/* WORK mobile */


/* @media screen and (max-width: 50em) {
	.device {
		width: 100%;
		height: auto;
		background-image: none;
	}http://localhost/smile-rnd/index.php
	.device__screen {
		margin: 0;
		border-radius: 0;
	}
} */

@media(max-width:768px){
	.work .poster__deco{
		margin: 0 -2.2em 0 auto;
		font-size: 1.5em;
		transform: rotate(0deg);
	}

	.team .poster__deco {
		margin: 0;
		font-size: 1.5em;
		transform: rotate(0deg);
	}

	.work .poster__box, .team .poster__box {
		position: absolute;
		top: -20px;
		left: 20px;
		width: 100px;
		height: 100px;
		border: 5px solid;
	}

	.work .poster__img {
		max-width: calc(100%);
		max-height: 800px;
		min-width: 300px;
	}

	.work .poster {
		margin: 0;
	}

	.work .btn--back, .team .btn--back {
		font-size: .75em;
		padding: .5rem;
	}
}



@media (max-width: 576px) {
	.work .poster__img, .team .poster__img {
    	max-width: 100%;
    	max-height: 800px;
    	min-width: 240px;
	}



}


.poster, .post__header {
	position: relative;
/* 	height: 80vh; 
	width: 80%;*/
}

.poster__caption {
	width: 100%;
	height: 100%;
	padding: 0 50px;
}

.poster__title {
    text-indent: -0.75em;
	padding: 2rem 3rem 0rem;
}



/* BOTH */

@media(max-width:576px){
	
	.poster {
		width: 100%;
		padding: 0 10px;
	}

	.poster__img {
		padding-right: 5vh;
	}

}

@media(max-width:576px){

	.poster__title {
		font-size: 2rem;
		padding: 2rem;
	}

	.work .poster__caption {
		width: 100%;
		height: 100%;
		padding: 0 0 0 30px ;
	}

	.team .poster__caption {
		padding: 0 60px 0 30px ;
	}

	.work .tabscontent__item {
		padding: 100px 60px 0 0;
	}
}










.tabsnav {
	z-index: 1000;
}

.tabsnav--hidden {
	pointer-events: none;
	visibility: hidden;
}

.tabsnav__item {
	position: relative;
	cursor: pointer;
}

.tabsnav__bar {
	width: 100%;
	height: 100%;
	position: absolute;
	/* pointer-events: none; */
	display: flex;
	flex-direction: column;
	padding: 1rem;
}

.tabsnav__content {
	position: relative;
	cursor: pointer;
	margin: 0;
	height: 100%;
	font-weight: normal;
	line-height: 1;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	pointer-events: none;
}

.tabsnav__title, .tabsnav__name, .tabsnav__img {
	position: relative;
}

.tabsnav__name {
	font-size: 1rem;
    background: rgba(255, 255, 255, .75);
    padding: 2px 6px;
}


.tabsnav__title {
	font-size: 1rem;
    background: rgba(255, 255, 255, .9);
	padding: 2px 6px;
	text-align: center;
}


/* Vertical Menu */
.tabsnav--vertical {
    display: flex;
    flex-wrap: wrap;
	flex-direction: row;
	justify-content: center;
}

.tabsnav--vertical .tabsnav__item {
	flex: none;
	width: 300px;
	height: 300px; 
}

@media (min-width: 1200px) {
	.tabsnav--vertical .tabsnav__item {
		width: 320px;
		height: 460px;
	}
}

@media (max-width: 1200px) {
	.tabsnav--vertical .tabsnav__item {
		width: 310px;
		height: 360px;
	}
}

@media (max-width: 992px) {
	.tabsnav--vertical .tabsnav__item {
		width: 340px;
		height: 420px;
	}
}

@media (max-width: 768px) {
	.tabsnav--vertical {
		justify-content: flex-start;
	}
	.tabsnav--vertical .tabsnav__item {
		height: 420px;
	}
	.tabsnav__bar {
		padding: 1rem 0;
	}
}


/* Individual menu styles (Basque names) */

/* Ander */
.tabsnav--ander {
/* 	left: 45px; */
}

.tabsnav--ander .tabsnav__bar {
	background: #fff;
}

.tabsnav--ander .tabsnav__title {
	color: #da3e5a;
}

.tabsnav--ander .tabsnav__item/*:not(:last-child)*/ {
/* 	margin: 0 10px 0 0; */
	padding: 1rem;
}

@media(max-width:576px){
	.tabsnav--ander .tabsnav__item {
		padding:  0;
	}
}

/* Zeru */
.tabsnav--zeru {
	background: #222525;
}

.tabsnav--zeru .tabsnav__item:first-child .tabsnav__bar {
	background: #161919;
}

.tabsnav--zeru .tabsnav__item:nth-child(2) .tabsnav__bar {
	background: #222525;
}

.tabsnav--zeru .tabsnav__item:nth-child(3) .tabsnav__bar {
	background: #2e3131;
}

.tabsnav--zeru .tabsnav__item:nth-child(4) .tabsnav__bar {
	background: #2e3131;
}

.tabsnav--zeru .tabsnav__item:nth-child(5) .tabsnav__bar {
	background: #2e3131;
}

.tabsnav--zeru .tabsnav__item:nth-child(6) .tabsnav__bar {
	background: #2e3131;
}

.tabsnav--zeru .tabsnav__item:nth-child(7) .tabsnav__bar {
	background: #2e3131;
}

.tabsnav--zeru .tabsnav__item:nth-child(8) .tabsnav__bar {
	background: #2e3131;
}

.tabsnav--zeru .tabsnav__item:nth-child(9) .tabsnav__bar {
	background: #2e3131;
}

.tabsnav--zeru .tabsnav__item:nth-child(3) .tabsnav__bar {
	background: #2e3131;
}


.tabsnav--zeru .tabsnav__title {
	color: #fff;
}

/* Ibai */
.tabsnav--ibai {
	top: 50%;
	margin-top: -115px;
}

.tabsnav--ibai .tabsnav__item {
	height: 70px;
}

.tabsnav--ibai .tabsnav__bar {
	background: #414a52;
}

.tabsnav--ibai .tabsnav__title {
	color: #fc0;
}

.tabsnav--ibai .tabsnav__item:not(:last-child) {
	margin: 0 0 10px 0;
}

@media screen and (max-width: 40em) {
	/* don't show a background if on mobile */
	.tabsnav {
		background: none;
	}
}









.column {
	width: 30vw;
}

.column__title {
	color: #ff764b;
}

.column__text {
	font-size: 2em;
	font-weight: normal;
	margin: 0;
	color: #fff4d7;
}

.box {
	position: relative;
	width: 40vw;
	min-width: 300px;
	height: calc(90vh - 120px);
	margin-top: 40px;
	background-size: cover;
	background-position: 50% 0;
}

.box__title {
	font-size: 6em;
	position: absolute;
	bottom: 0;
	left: -1em;
	margin: 0;
	color: #fff;
}


.card-min-icon {
	width: 20px; 
	position: absolute; 
	top: 20px;
}


.grey-zone {
	background: #f5f5fa;
}





/* Archive */
:root {
	/* --color-text: #131313; */
	--color-bg: #fff;
/* 	--color-link: #000;
	--color-link-hover: #131313; */
	--color-row-border: #f0f0f0;
	--color-bg-row-hover: #f0f0f0;
	--color-text-alt: #000;
	--padding-sides: 2rem;
	--padding-row: 2rem;
	--image-gap: 1vw;
	--image-gap-large: 2vw;
	--img-size: 5vw;
	--img-size-large: 21vw;
}

@media (width > 992px) {
	:root {
		--img-size-large: 14vw;
	}
}





.archives-project .oh {
	position: relative;
    overflow: hidden;
}

.archives-project .oh__inner {
	will-change: transform;
	display: inline-block;
	padding-bottom: 10px;
}


.archives-project .hover-line {
	overflow: hidden;
	position: relative;
}

.archives-project .hover-line::before {
	content: '';
	height: 1px;
	width: 100%;
	background: currentColor;
	position: absolute;
	top: 92%;
	transition: transform 0.3s;
	transform-origin: 0% 50%;
}

.archives-project .hover-line:hover::before {
	transform: scaleX(0);
	transform-origin: 100% 50%;
}

.archives-project a {
	text-decoration: none;
	outline: none;
	cursor: pointer;
}

.archives-project .cover {
	background: var(--color-bg-row-hover);
	width: 100%;
	height: 0;
	opacity: 0;
	pointer-events: none;
	z-index: 10;
	position: fixed;
	left: 0;
	will-change: height, top;
}

.archives-project .content {
	position: relative;
	z-index: 100;
	overflow: hidden;
}

.archives-project .content .row {
	z-index: 1 !important;
	color: var(--color-text) !important;
	display: grid !important;
	position: relative !important;
	cursor: pointer !important;
	grid-template-rows: var(--img-size) !important;
	grid-template-columns: auto 1fr !important;
	grid-column-gap: 5vw !important;
	align-items: center !important;
	padding: 2rem 0 !important;
	border-top: 1px solid var(--color-text) !important;
	transition: background-color 0.3s ease-out, border-color 0.3s ease-out !important;
}

.archives-project .content .row:hover {
	background-color: transparent;
}

.section.content .row--current {
	z-index: 11;
	transition: border-color 0.3s ease-out;
}

.archives-project .cell {
	position: relative;
}

.archives-project .cell__title,
.archives-project .preview__item-title {
	margin: 0;
	font-size: clamp(1.953rem,4vw,3.052rem);
	position: relative;
	font-weight: 400;
	line-height: 1;
	font-family: 'TT Wellingtons', sans-serif;
	white-space: nowrap;
}

.archives-project .cell__title--switch {
	font-weight: 700;
}

.archives-project .cell__title {
	display: grid;
	grid-template-rows: 100% 100%;
}

.archives-project .cell--images {
	display: grid;
	align-content: center;
	grid-auto-columns: auto;
	grid-auto-flow: column;
	grid-gap: var(--image-gap);
	justify-content: end;
	margin-left: auto;
}

.archives-project .cell__img {
	width: var(--img-size);
	display: grid;
	position: relative;
	grid-template-columns: 100%;
	grid-template-rows: auto auto;
	will-change: transform, opacity;
	opacity: 0;
}

.archives-project .cell__img-inner {
	background-size: cover;
	background-position: 50% 50%;
	aspect-ratio: 1;
	width: 100%;
	border-radius: calc(var(--image-gap) / 3);
}

.archives-project .cell__img-title {
	max-width: 100%;
	font-size: 1rem;
	font-weight: 300;
	color: var(--color-text-alt);
	margin: 0;
	height: 0;
	opacity: 0;
	white-space: nowrap;
	will-change: opacity, transform;
	display: none;
}

.archives-project .preview {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	pointer-events: none;
	z-index: 200;
}


.archives-project .preview__close {
	position: absolute;
	top: 0;
	right: 0;
	font-size: 2rem;
	font-weight: 400;
	line-height: 1;
	padding: 2rem;
	cursor: pointer;
	opacity: 0;
	font-family: sans-serif;
	border: none;
	background: transparent;
	text-align: end;
	margin-top: 0;
}

.archives-project .preview__close--show {
	pointer-events: auto;
}

.archives-project .preview__item {
	width: 100%;
	height: 100%;
	display: grid;
	align-items: center;
    justify-items: center;
	align-content: center;
	grid-gap: 10vh;
	height: 0;
	opacity: 0;
	overflow: hidden;
}

.archives-project .preview__item--current {
	pointer-events: auto;
	height: 100vh;
	opacity: 1;
	background-color: white;
}

.archives-project .preview__item-title {
	font-weight: 700;
	font-size: clamp(1.563rem, 6vw, 3.815rem);
}

.archives-project .grid {
	position: relative;
	display: grid;
	max-width: 1200px;
	width: 100%;
    grid-gap: var(--image-gap-large);
	justify-content: center;
	justify-items: center;
	--img-size: var(--img-size-large);
	grid-template-columns: repeat(4,var(--img-size));
	grid-template-rows: repeat(2,var(--img-size));
}

.archives-project .grid .cell__img {
	width: var(--img-size-large);
}


.archives-project a.btn {
	opacity: 0;
	transition: opacity .25s ease-out;
}

.oh .archives-project a.btn {
	opacity: 1;
	transition: opacity .25s 1s ease-out;
}





/* Coaching */
.coaching {
	overflow-x: visible !important;
}

.overflow-none {
	overflow-x: hidden !important;
}

.img-sized {
	padding: 0 1rem;
	max-width: 300px;
}

@include media-breakpoint-up(lg) {
	.img-sized {
		position: absolute;
		bottom: 0;
		right: 0;
		max-width: 400px;
	}
}


// Single project
.other-projects {
	transform: scale(1);
	transition: all .2s ease-in-out;
}

.other-projects:hover {
	transform: scale(1.05);
	transition: all .2s ease-in-out;
}