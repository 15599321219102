$spacer: 1rem;
$spacers: (
    0: 0,
    1: $spacer * .25, // 4
    2: $spacer * .5, // 8
    3: $spacer, // 16
    4: $spacer * 1.5, // 24
    5: $spacer * 3, // 48
);

$custom-spacers: (
    6: $spacer * 3.5, // 56
    7: $spacer * 4, // 64
    8: $spacer * 5, // 80
    9: $spacer * 6, // 96
    10: $spacer * 7.5, // 120
    11: $spacer * 8.5, // 136
    12: $spacer * 10, // 160
    13: $spacer * 11.5, // 184
    14: $spacer * 13.5, // 216
    15: $spacer * 16, // 256
);

$spacers: map-merge($spacers, $custom-spacers);

$breakpoints: (
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);

@each $breakpoint, $size in $breakpoints {
  @each $spacer, $value in $spacers {
    $class-name: "m-#{$breakpoint}-#{$spacer}";

    @media (min-width: $size) {
      .#{$class-name} {
        margin: $value !important;
      }
    }
  }

  @each $spacer, $value in $spacers {
    $class-name: "p-#{$breakpoint}-#{$spacer}";

    @media (min-width: $size) {
      .#{$class-name} {
        padding: $value !important;
      }
    }
  }

  @each $spacer, $value in $spacers {
    $class-name: "g-#{$breakpoint}-#{$spacer}";

    @media (min-width: $size) {
      .#{$class-name} {
        gap: $value !important;
      }
    }
  }
}



// Css third part
@import "~bootstrap/scss/bootstrap";
@import '../../../node_modules/swiper/swiper-bundle.min.css';
@import '../../../node_modules/splitting/dist/splitting.css';
@import '../../../node_modules/splitting/dist/splitting-cells.css';

// Css custom
// @import './helper.scss';
@import './normalize.scss';
@import './abstract.scss'; 
@import './base.scss';
@import './layout.scss';
@import './pages.scss';
@import './component.scss';


:root {
    @each $spacer, $value in $spacers {
        --theme-spacer-#{$spacer}: #{$value};
    }
}


// width changed
$container-max-widths: (
  xxl: 1400px,
);
  
@each $breakpoint,
$container-max-width in $container-max-widths {
  .container-#{$breakpoint} {
    @extend .container-fluid;
  }
  
  @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
    %responsive-container-#{$breakpoint} {
      max-width: $container-max-width;
    }
  }
}

@media (min-width: 1400px) {
    .container,
    .container-sm,
    .container-md,
    .container-xl,
    .container-xxl {
        max-width: 1398px;
    }
}

