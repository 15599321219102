:root {
    /* Underline effet : https://sharkcoder.com/visual/underline | https://codepen.io/Ash/pen/WBMxpp */

	/* The intrinsic width of the underline stroke (in pixels). Don't specify the units */
	--underline-intrinsic-width: 8;
	
	/* The actual width of the underline stroke we want to render (in pixels). Don't specify the units! */
	--underline-width: 10;
	
	/* The colour used to draw the underline */
	--underline-color: rgba(252, 208, 35, .7);
	
	/* We need to know the width of the cap images so that we can position everything on the x axis accordingly. */
	--underline-cap-width: 4px;
	
	/* The border is positioned relative to the bottom of the line. We can move it upwards a little to create an overlap effect. */
	--underline-offset-y: -2px;
	
	/* The padding to add to the x axis. By default, the caps would be aligned with the beginning and end of the line. */
	--underline-padding-x: 0.12em;


	/* Shadow effects generated : https://www.joshwcomeau.com/shadow-palette/ */
	--shadow-color: 0deg 0% 73%;

	--shadow-elevation-low:
	  0.3px 0.5px 0.5px hsl(var(--shadow-color) / 0.44),
	  0.3px 0.7px 0.6px -2.3px hsl(var(--shadow-color) / 0.3),
	  0.9px 1.7px 1.6px -4.6px hsl(var(--shadow-color) / 0.17);

	--shadow-elevation-medium:
	  0.3px 0.5px 0.5px hsl(var(--shadow-color) / 0.46),
	  0.5px 0.9px 0.8px -1.5px hsl(var(--shadow-color) / 0.35),
	  1.5px 3px 2.7px -3.1px hsl(var(--shadow-color) / 0.25),
	  4.3px 8.5px 7.7px -4.6px hsl(var(--shadow-color) / 0.14);



	  /* theme colors */

	  --white: #fff;

	
  }


/* colors */ 
$seablue-900: #123B5E;
$seablue-800: #15396C;
$seablue-600: #213A8D;
$seablue-300: #2D4F99;
$seablue-200: #467FAF;
$green-800: #4A7425;
$green-600: #5F8422;
$green-500: #75961F;
$green-300: #92AE1B;
$green-200: #92AE1B;
$yellow : #ffc008;
$pink : #da3e5a;
$deep-pink: #FF3C5C;
$orange: #FF7417;

/* gradient */
$seablue-gradient: linear-gradient(
	150deg, 
	$seablue-900 0%, 
	$seablue-800 40%, 
	$seablue-600 55%, 
	$seablue-300 70%, 
	$seablue-200 90%
);

$greenish-gradient : linear-gradient(
	165deg,
	$green-800 0,
	$green-600 50%,
	$green-500 70%,
	$green-300 80%,
	$green-200 90%,
);

/* Gradients */
$linear-red-orange : linear-gradient(
    135deg,
    rgba(255,71,78,1) 0%, 
    rgba(255,115,24,1) 100%
);

$linear-gradient-dark-light-green: transparent linear-gradient(237deg, #AEC316 0%, #003831 100%) 0% 0% no-repeat padding-box;
$linear-red-orange-yellow : transparent linear-gradient(270deg, #FFC000 0%, #FFC000 0%, $deep-pink 123.88%, $deep-pink 123.88%);
$linear-yellow-orange: transparent linear-gradient(119deg, #FFC000 -40%, #FF7417 100%) 0% 0% no-repeat padding-box;
$linear-orange-red: transparent linear-gradient(119deg, #FF7417 0%, #FF3C5C 100%) 0% 0% no-repeat padding-box;


$assetPath: 'https://www.makingyousmile.fr/wp-content/themes/mysmile/integration/src/assets/fonts';

/* Colors */
@font-face {
    font-family: 'TT Wellingtons';
    src: url('#{$assetPath}/TTWellingtons-ThinItalic.eot');
    src: local('TT Wellingtons Thin Italic'), local('#{$assetPath}/TTWellingtons-ThinItalic'),
        url('#{$assetPath}/TTWellingtons-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('#{$assetPath}/TTWellingtons-ThinItalic.woff2') format('woff2'),
        url('#{$assetPath}/TTWellingtons-ThinItalic.woff') format('woff'),
        url('#{$assetPath}/TTWellingtons-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'TT Wellingtons';
    src: url('#{$assetPath}/TTWellingtons-Regular.eot');
    src: local('TT Wellingtons Regular'), local('#{$assetPath}/TTWellingtons-Regular'),
        url('#{$assetPath}/TTWellingtons-Regular.eot?#iefix') format('embedded-opentype'),
        url('#{$assetPath}/TTWellingtons-Regular.woff2') format('woff2'),
        url('#{$assetPath}/TTWellingtons-Regular.woff') format('woff'),
        url('#{$assetPath}/TTWellingtons-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'TT Wellingtons';
    src: url('#{$assetPath}/TTWellingtons-Italic.eot');
    src: local('TT Wellingtons Italic'), local('#{$assetPath}/TTWellingtons-Italic'),
        url('#{$assetPath}/TTWellingtons-Italic.eot?#iefix') format('embedded-opentype'),
        url('#{$assetPath}/TTWellingtons-Italic.woff2') format('woff2'),
        url('#{$assetPath}/TTWellingtons-Italic.woff') format('woff'),
        url('#{$assetPath}/TTWellingtons-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'TT Wellingtons';
    src: url('#{$assetPath}/TTWellingtons-Thin.eot');
    src: local('TT Wellingtons Thin'), local('#{$assetPath}/TTWellingtons-Thin'),
        url('#{$assetPath}/TTWellingtons-Thin.eot?#iefix') format('embedded-opentype'),
        url('#{$assetPath}/TTWellingtons-Thin.woff2') format('woff2'),
        url('#{$assetPath}/TTWellingtons-Thin.woff') format('woff'),
        url('#{$assetPath}/TTWellingtons-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'TT Wellingtons';
    src: url('#{$assetPath}/TTWellingtons-Medium.eot');
    src: local('TT Wellingtons Medium'), local('#{$assetPath}/TTWellingtons-Medium'),
        url('#{$assetPath}/TTWellingtons-Medium.eot?#iefix') format('embedded-opentype'),
        url('#{$assetPath}/TTWellingtons-Medium.woff2') format('woff2'),
        url('#{$assetPath}/TTWellingtons-Medium.woff') format('woff'),
        url('#{$assetPath}/TTWellingtons-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'TT Wellingtons';
    src: url('#{$assetPath}/TTWellingtons-DemiBold.eot');
    src: local('TT Wellingtons DemiBold'), local('#{$assetPath}/TTWellingtons-DemiBold'),
        url('#{$assetPath}/TTWellingtons-DemiBold.eot?#iefix') format('embedded-opentype'),
        url('#{$assetPath}/TTWellingtons-DemiBold.woff2') format('woff2'),
        url('#{$assetPath}/TTWellingtons-DemiBold.woff') format('woff'),
        url('#{$assetPath}/TTWellingtons-DemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'TT Wellingtons';
    src: url('#{$assetPath}/TTWellingtons-BoldItalic.eot');
    src: local('TT Wellingtons Bold Italic'), local('#{$assetPath}/TTWellingtons-BoldItalic'),
        url('#{$assetPath}/TTWellingtons-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('#{$assetPath}/TTWellingtons-BoldItalic.woff2') format('woff2'),
        url('#{$assetPath}/TTWellingtons-BoldItalic.woff') format('woff'),
        url('#{$assetPath}/TTWellingtons-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'TT Wellingtons';
    src: url('#{$assetPath}/TTWellingtons-ExtraBoldItalic.eot');
    src: local('TT Wellingtons ExtraBold Italic'), local('#{$assetPath}/TTWellingtons-ExtraBoldItalic'),
        url('#{$assetPath}/TTWellingtons-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('#{$assetPath}/TTWellingtons-ExtraBoldItalic.woff2') format('woff2'),
        url('#{$assetPath}/TTWellingtons-ExtraBoldItalic.woff') format('woff'),
        url('#{$assetPath}/TTWellingtons-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'TT Wellingtons';
    src: url('#{$assetPath}/TTWellingtons-BlackItalic.eot');
    src: local('TT Wellingtons Black Italic'), local('#{$assetPath}/TTWellingtons-BlackItalic'),
        url('#{$assetPath}/TTWellingtons-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('#{$assetPath}/TTWellingtons-BlackItalic.woff2') format('woff2'),
        url('#{$assetPath}/TTWellingtons-BlackItalic.woff') format('woff'),
        url('#{$assetPath}/TTWellingtons-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'TT Wellingtons';
    src: url('#{$assetPath}/TTWellingtons-ExtraLight.eot');
    src: local('TT Wellingtons ExtraLight'), local('#{$assetPath}/TTWellingtons-ExtraLight'),
        url('#{$assetPath}/TTWellingtons-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('#{$assetPath}/TTWellingtons-ExtraLight.woff2') format('woff2'),
        url('#{$assetPath}/TTWellingtons-ExtraLight.woff') format('woff'),
        url('#{$assetPath}/TTWellingtons-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'TT Wellingtons';
    src: url('#{$assetPath}/TTWellingtons-MediumItalic.eot');
    src: local('TT Wellingtons Medium Italic'), local('#{$assetPath}/TTWellingtons-MediumItalic'),
        url('#{$assetPath}/TTWellingtons-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('#{$assetPath}/TTWellingtons-MediumItalic.woff2') format('woff2'),
        url('#{$assetPath}/TTWellingtons-MediumItalic.woff') format('woff'),
        url('#{$assetPath}/TTWellingtons-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'TT Wellingtons';
    src: url('#{$assetPath}/TTWellingtons-ExtraBold.eot');
    src: local('TT Wellingtons ExtraBold'), local('#{$assetPath}/TTWellingtons-ExtraBold'),
        url('#{$assetPath}/TTWellingtons-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('#{$assetPath}/TTWellingtons-ExtraBold.woff2') format('woff2'),
        url('#{$assetPath}/TTWellingtons-ExtraBold.woff') format('woff'),
        url('#{$assetPath}/TTWellingtons-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'TT Wellingtons';
    src: url('#{$assetPath}/TTWellingtons-ExtraLightItalic.eot');
    src: local('TT Wellingtons ExtraLight Italic'), local('#{$assetPath}/TTWellingtons-ExtraLightItalic'),
        url('#{$assetPath}/TTWellingtons-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
        url('#{$assetPath}/TTWellingtons-ExtraLightItalic.woff2') format('woff2'),
        url('#{$assetPath}/TTWellingtons-ExtraLightItalic.woff') format('woff'),
        url('#{$assetPath}/TTWellingtons-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'TT Wellingtons';
    src: url('#{$assetPath}/TTWellingtons-Black.eot');
    src: local('TT Wellingtons Black'), local('#{$assetPath}/TTWellingtons-Black'),
        url('#{$assetPath}/TTWellingtons-Black.eot?#iefix') format('embedded-opentype'),
        url('#{$assetPath}/TTWellingtons-Black.woff2') format('woff2'),
        url('#{$assetPath}/TTWellingtons-Black.woff') format('woff'),
        url('#{$assetPath}/TTWellingtons-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'TT Wellingtons';
    src: url('#{$assetPath}/TTWellingtons-Bold.eot');
    src: local('TT Wellingtons Bold'), local('#{$assetPath}/TTWellingtons-Bold'),
        url('#{$assetPath}/TTWellingtons-Bold.eot?#iefix') format('embedded-opentype'),
        url('#{$assetPath}/TTWellingtons-Bold.woff2') format('woff2'),
        url('#{$assetPath}/TTWellingtons-Bold.woff') format('woff'),
        url('#{$assetPath}/TTWellingtons-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'TT Wellingtons';
    src: url('#{$assetPath}/TTWellingtons-DemiBoldItalic.eot');
    src: local('TT Wellingtons DemiBold Italic'), local('#{$assetPath}/TTWellingtons-DemiBoldItalic'),
        url('#{$assetPath}/TTWellingtons-DemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('#{$assetPath}/TTWellingtons-DemiBoldItalic.woff2') format('woff2'),
        url('#{$assetPath}/TTWellingtons-DemiBoldItalic.woff') format('woff'),
        url('#{$assetPath}/TTWellingtons-DemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'TT Wellingtons';
    src: url('#{$assetPath}/TTWellingtons-Light.eot');
    src: local('TT Wellingtons Light'), local('#{$assetPath}/TTWellingtons-Light'),
        url('#{$assetPath}/TTWellingtons-Light.eot?#iefix') format('embedded-opentype'),
        url('#{$assetPath}/TTWellingtons-Light.woff2') format('woff2'),
        url('#{$assetPath}/TTWellingtons-Light.woff') format('woff'),
        url('#{$assetPath}/TTWellingtons-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}





