*,
*::after,
*::before {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	/* 	background: rgba(10, 114, 200, 0.15); 
	border: 1px solid rgba(0, 0, 0, 0.25);*/
}

.clearfix:before,
.clearfix:after {
	content: '';
	display: table;
}

.clearfix:after {
	clear: both;
}

html,
body {
  height: 100%;
}

body {
	font-family: 'Poppins', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 1.2rem;
	line-height: 1.6;
	font-weight: 300;
	margin: 0;
	--color-text: #000;
	// --color-bg: #efecea; #faf5e3
	--color-link: #8d561f;
	--color-link-hover: #000;
	color: var(--color-text);
	background-color: white;
	--cursor-stroke: none;
    --cursor-fill: #988c67;
    /* --cursor-stroke-width: 1px; */
	overflow: hidden;
	overflow-y: scroll;
}

@media(max-width:768px) {
	body
	{
		font-size: 1.2rem;
	}
}

main a {
	text-decoration: none;
	color: #212529;
	outline: none;
	cursor: pointer;
	border-bottom: 1px solid transparent;
}

main a:hover,
main a:focus {
	color: var(--color-link-hover);
	outline: none;
}

a.active {
	background: $linear-red-orange-yellow;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	font-weight: 600;
}


h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
	font-family: 'TT Wellingtons', sans-serif;
}


h1, .h1 {
	font-size: 2.75rem;
	font-weight: 600;
	text-wrap: balance;
}

h2, .h2 {
	font-size: 2.25rem;
	margin-bottom: 2.25rem;
	font-weight: 600;
	text-wrap: balance;
}

h3, .h3 {
	font-size: 2rem;
	margin-bottom: 2.1rem;
	font-weight: 600;
	text-wrap: balance;
}

h4, .h4 {
	font-size: 1.75rem;
	margin-bottom: 1.75rem;
	font-weight: 500;
}

h5, .h5 {
	font-size: 1.25rem;	
	font-weight: 600;
	margin-bottom: 1.25rem;
}

h6, .h6 {
	font-weight: 500;
	margin-bottom: 1.25rem;
}


@include media-breakpoint-up(lg) {
	h1, .h1 {
		font-size: 3.25rem;
		font-weight: 600;
	}

	h2, .h2 {
		font-size: 2.75rem;
		margin-bottom: 3rem;
		font-weight: 600;
	}

	h3, .h3 {
		font-size: 2.25rem;
		margin-bottom: 2.25rem;
		font-weight: 600;
	}

	h4, .h4 {
		font-size: 1.9rem;
		margin-bottom: 1.9rem;
		font-weight: 500;
	}

	h5, .h5 {
		font-weight: 600;
		margin-bottom: 1.4rem;
	}

	h6, .h6 {
		font-weight: 500;
		margin-bottom: 1.25rem;
	}
}

@include media-breakpoint-up(xl) {
	h1,
		.h1 {
			font-size: 4.75rem;
			margin-bottom: 3.25rem;
		}
	}

@include media-breakpoint-up(xxl) { 
	h1, .h1 {
		font-size: 4.75rem;
		margin-bottom: 3.25rem;
	}
	h2, .h2 {
		font-size: 3.75rem;
		margin-bottom: 4rem;
	}
	h3, .h3 {
		font-size: 2.8rem;
		margin-bottom: 2.8rem;
	}
	h4, .h4 {
		font-size: 2rem;
		margin-bottom: 1.25rem;
	}
}



.work h1 {
	font-size: 22px;
	font-weight: 500;
	text-transform: uppercase;
}


p {
	margin-bottom: 2rem;
}

li {
    margin-bottom: .5rem;
}


blockquote {
	font-size: 1.25rem;
	font-style: italic;
	position: relative;
	margin-top: 100px;
	padding-bottom: 2rem;
}

blockquote::before {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	// content: url('/wp-content/themes/mysmile/integration/src/assets/img/svg/mys-quote.svg');
	top: -100px;
}

blockquote.white::before {
	// content: url('/wp-content/themes/mysmile/integration/src/assets/img/svg/mys-quote-white.svg') !important;
}

blockquote h3, blockquote .h3 {
	font-size: 2.25rem;
	margin-bottom: 1.25rem;
	font-weight: 600;
	font-style: normal;
}

blockquote h4 {
	font-style: normal !important;
}


/* form */

label {
	width: 100% !important;
	font-size: 600;
}

button, input, select, textarea {
    font-family: inherit;
    font-size: 100%;
	margin: .5rem 0;
    border: 0;
    border-bottom: 2px solid;
    padding: 0.5rem 0.25rem;
	width: 100%;
}

input:focus-visible, select:focus-visible, textarea:focus-visible {
	outline: 0;
	border-bottom: 4px solid;
	transition: all .1s linear;
}

label:has(input:focus-visible), label:has(select:focus-visible), label:has(textarea:focus-visible) {
	font-weight: bold;
}


/* btn */

.btn {
	width: fit-content;
	font-weight: 500;
    border-radius: 30px;
    padding: 1rem 2rem;
}

.btn-small {
    border-radius: 20px !important;
	padding: 0.3rem 1.5rem !important;
}

.btn-primary {
	border: 2px solid transparent;
	background-color: $pink;
	color: white;
}

.btn-primary:hover {
	border: 2px solid $pink;
	background-color: transparent;
	color: $pink;
}

.btn-info {
	border: 2px solid transparent;
	background-color: $yellow;
	color: white;
}

.btn-info:hover {
	border: 2px solid $yellow;
	background-color: transparent;
	color: $yellow;
}


.btn-outline-primary {
    border: 2px solid $pink;
    color: $pink;
    background: transparent;
}

.btn-outline-primary:hover {
    color: white;
    background: $pink;
	border: 2px solid transparent;
}

.btn-outline-primary:focus-visible, .btn-outline-primary:focus {
	border: none !important;
	box-shadow: none;
	background: $pink;
	color: #fff;
	outline: none;
	border-color: none !important;
}


.btn-outline-primary::after {
	content: none;
}


.btn-outline-secondary {
	color: #fff;
	background: $pink;
	font-weight: bold;
    border: 2px solid $pink;
}

.btn-outline-secondary:hover {
	color: $pink;
	background: transparent;
    border: 2px solid $pink;
}

.bi-linkedin::before {
	color: $pink;
}

.btn-outline-black {
	color: black;
	background: transparent;
    border: 2px solid black !important;
}

.btn-outline-black:hover {
	background-color: black;
	color: white;
	border: 2px solid black;
}

.btn-outline-white {
	color: white;
	background: transparent;
    border: 2px solid white !important;
}

.btn-outline-white:hover {
	background-color: white;
	color: #FF3C5C;
	border: 2px solid white;
}

.btn-outline-deep-pink {
	color: $deep-pink;
	background: transparent;
    border: 2px solid $deep-pink;
}

.btn-outline-deep-pink:hover {
	background-color: $deep-pink;
	color: white;
	border: 2px solid $deep-pink;
}

.btn--back {
	font-size: 1.5em;
	position: fixed;
	z-index: 1101;
	top: 1rem;
	right: .75rem;
	opacity: 0;
	color: $pink;
	background: #fff;
	margin: .375rem;
	border: 1px solid currentColor;
	border-radius: 0;
	padding: 1rem;
}

.btn--menu {
	font-size: 0.85em;
	position: absolute;
	z-index: 1000;
	top: 0;
	left: 0;
	display: none;
	margin: 10px 0 0 6px;
}

.btn--menu-active .icon--menu,
.icon--cross {
	display: none;
}

.btn--menu-active .icon--cross {
	display: block;
}






/* Colors */
.color-green-800 {
	color : $green-800;
}

.color-pink {
	color : $pink;
}



/* Backgrounds */

.bg-seablue-gradient {
	background-image: $seablue-gradient !important;
}

.bg-greenish-gradient {
	background-image: $greenish-gradient;
}

.bg-red-orange {
	background: rgb(255,60,92); 
	// background: linear-gradient(135deg, rgba(255,71,78,1) 0%, rgba(255,115,24,1) 100%);
	background: $linear-red-orange;
}

.bg-red-orange-yellow {
	background: $linear-red-orange-yellow;
}

.bg-linear-yellow-orange {
	background: $linear-yellow-orange;
}

.bg-linear-orange-red {
	background: $linear-orange-red;
}

.bg-white {
	background-color: white;
}

.bg-yellow {
	background-color: $yellow;
}

.bg-green-800 {
	background-color: $green-800;
}

.bg-pink {
	background-color: $pink;
}

.lazy-img {
	filter: blur(5px);
}

.img-circle {
	border-radius: 100%;
}


/* Width */
.xl-width {
    max-width: 1120px;
    margin-left: auto;
    margin-right: auto;
}

.lg-width {
    max-width: 980px;
    margin-left: auto;
    margin-right: auto;
}

.md-width {
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;
}

.sm-width {
	max-width: 600px;
	margin-left: auto;
    margin-right: auto;
}