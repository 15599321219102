.header {
	margin-top: -60px;
	display: flex;
	align-items: center;
}

header a {
	color: inherit;
}

.icon-fluid {
	margin-top: -400px;
	margin-left: 60vw;
	width: 130px;
}

@include media-breakpoint-up(lg) {
	.icon-fluid {
		margin-top: -450px;
	}
}

.clarity {
	max-width: 150px;
}

.clarity-small {
	max-width: 100px !important;
}


/* Main */
main {
	overflow-x: hidden !important;
}

ul.tags {
	padding-left: 0;
}

ul.tags li {
	list-style: none;
	display: inline-block;
}

ul.tags li a {
	text-decoration: none;
}

ul.tags li:after {
	content: "·";
	padding-left: 5px;
}

ul.tags li:last-of-type::after {
	content: "";
}

.frame {
	padding: 3rem 5vw;
	text-align: center;
	position: relative;
	z-index: 1000;
}

.frame__title {
	font-size: 1rem;
	margin: 0 0 1rem;
	font-weight: normal;
}

.frame__links {
	margin: 0.35rem 0 0 0;
}

.frame__links a:not(:last-child) {
	margin-right: 1rem;
}

.frame__home {
	text-decoration: none;
	color: white;
	display: inline-block;
	height: 1.75rem;
	font-weight: 700;
	margin: 2rem 0 0 0;
}


@include media-breakpoint-up(md) {
	.frame_home {
		margin: 2.65rem 0 0 0;
	}
}

.frame__home:hover, .frame__menu:hover {
	cursor: pointer !important;
}

.frame__home-title {
	font-weight: 700;
}

.frame__home-sub {
	align-self: flex-end;
	margin-left: 0.5rem;
}

.frame__about {
	display: inline-block;
	margin: 1rem 0;
	font-weight: 700;
	text-decoration: none;
	color: #131313;
}

.frame__about-item--current {
	display: inline-block;
}

.content__item--current .content__figure {
	transform: translateX(-16px) !important;
}

.content__item--about {
	opacity: 0;
	transition: all .5s;
	transform: translateY(40px);
}

.content__item--about.content__item--current {
	opacity: 1;
	transform: translateY(0);
	transition: all .4s .5s;
}



.content {
	position: relative;
	cursor: default;
}

header.content nav a {
	color: white;
}

header.content nav ul li a:hover, header.content nav ul li a:focus {
	color: transparent !important;
    -webkit-text-stroke-width: 2px !important;
    -webkit-text-stroke-color: white !important;
	transition: all .1s ease-out;
}

.content__item {
	grid-area: content-item;
	opacity: 0;
	pointer-events: none;
	display: flex;
	align-content: center;
	flex-direction: column;
	overflow: hidden;
}

.content__item--home {
	justify-content: center;
}

.content__item--current {
	overflow: visible;
	height: auto;
	opacity: 1;
	pointer-events: auto;
}

.content__item--about {
	min-height: 100dvh;
	position: fixed;
	top: 0;
	z-index: 10;
	background: $linear-orange-red;
	transition: background .3s 1s ease-in-out;
	/* overflow-y: scroll; */
}

.content__item--about nav {
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	display: flex;
	flex-direction: column;
    align-items: start;
}

.content__item--about nav ul li {
	font-family: "TT Wellingtons", sans-serif;
    font-size: 2.25rem;
    font-weight: 600;
}

@include media-breakpoint-up(xl) {
	.content__item--about nav {
		padding-left: 5vw;
	}

	.content__item--about nav ul li {
		font-family: "TT Wellingtons", sans-serif;
		font-size: 3.25rem;
		font-weight: 600;
	}
}

@media (height < 400px) {
	.content__item--about nav ul li {
		font-size: 1.5rem;
		line-height: 1.75rem;
	}
}

.content__item--about nav ul li:hover {
    transform: scale(1.05);
    transform-origin: center;
    transition: all .1s linear;
}

.whitespace {
	opacity: 0;
}

.content__paragraph {
	position: relative;
	overflow: hidden;
	flex: none;
}

.content__paragraph--first {
	margin-top: auto;
}

.content__paragraph--last {
	margin-bottom: auto;
}

.content__paragraph--large {
	font-size: 10vw;
}

.content__paragraph--right {
	align-self: flex-end;
}

.char {
	will-change: transform;
}

.cursor {
	display: none;
}

@media screen and (min-width: 53em) {
	.frame {
		position: absolute;
		text-align: left;
		top: 0;
		left: 0;
		display: grid;
		align-content: space-between;
		width: 100%;
		max-width: none;
		height: 100vh;
		padding: 3rem 8vw;
		pointer-events: none;
		grid-template-columns: 20% 60% 20%;
		grid-template-rows: auto auto auto;
		grid-template-areas: 'home title about'
							'... ... ...'
							'... ... credits';
	}
	.frame__menu {
		/* position: absolute;
		right: 4rem;
		z-index: 10; */
	}
	.frame__home {
		display: flex;
		grid-area: home;
		justify-content: flex-start;
		justify-self: start;
		margin: 0;
	}
	.frame__title-wrap {
		grid-area: title;
		text-align: center;
	}
	.frame__title {
		margin: 0;
	}
	.frame__links {
		margin: 1rem 0 0 0;
	}
	.frame__about {
		margin: 0;
		grid-area: about;
		align-self: start;
		justify-self: end;
	}
	.frame__credits {
		text-align: right;
		grid-area: credits;
		justify-self: end;
	}
	.frame a {
		pointer-events: auto;
	}
	.content__title-inner--offset {
	    margin-top: 3rem;
	}
	.content__title-seperator {
		margin: 0 2rem;
	}
	.content__item--home {
		max-height: none;
	}
	.content__item-credits {
		align-self: flex-end;
		max-width: 260px;
		text-align: right;
		padding: 0;
	}
}

@media (any-pointer: fine) {
	.cursor {
		position: fixed;
		top: 0;
		left: 0;
		display: block;
		pointer-events: none;
	}
	.cursor__inner {
		fill: var(--cursor-fill);
		stroke: var(--cursor-stroke);
		stroke-width: var(--cursor-stroke-width);
		opacity: 0.5;
	}
}


/* Footer */

footer a {
	color: white;
	text-decoration: none;
	position: relative;
}

footer a:hover {
	color: #FF3C5C;
}

/* footer a::after {
	content: "->";
	position: absolute;
	right: -25px;
}

footer a:has(img)::after {
	content: "";
} */

footer ul {
	list-style: none !important;
	padding-left: 0;
}